body {
  margin-right: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.promo {
  margin-bottom: 30px;
  font-size: 25px;
  width: 70%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.45);
  border: 1px solid black;
  border-radius: 10px;
}

.footer{
  padding: 5px;
  position: fixed;
  background-image: url('./images/metbg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: 'Courgette', cursive;
  box-shadow: 0px -1px 5px #000000;
  color: red;
}

.homeImg1 {
  filter: drop-shadow(-13px 10px 10px #000000);
}

.homeImg2 {
  filter: drop-shadow(0px 10px 10px #000000);
}

.homeImg3 {
  filter: drop-shadow(13px 10px 10px #000000);
}

#rc_logo {
  padding: 3px;
  position: absolute;
  color: #fff;
  font-size: 19px;
  font-family: Poppins;
  text-decoration: none;
  top: 5px;
  left: 7px;
  display: none;
 }

 #rc_logo a { 
 color: inherit; 
 text-decoration: none;
 } 

 #rc_logo:hover {
  transform: scale(1.15);
 }
 
 .rc_nav {
  width: 100%;
  position: fixed;
   overflow: hidden;
   background-color: #231F20;
   text-align: center;
   z-index: 1;
   height: 65px;
   box-shadow: 0px 1px 5px #000000;
 }
 
 .rc_nav a {
  display: inline-block;
  margin-right: -4px;
  color: white;
  padding: 15px 15px;
  text-decoration: none;
  font-family: 'Courgette', cursive;
  font-size: 15px;
  -webkit-transition: background 0.3s linear;
  -moz-transition: background 0.3s linear;
  -ms-transition: background 0.3s linear;
  -o-transition: background 0.3s linear;
  transition: background 0.3s linear;
 }
 
.rc_nav a:hover {
   color: #ff0e0e;
   transform: scale(1.15);
 }
 
.rc_nav .icon {
   display: none;
 }
 
.rc_content {
   text-align: center; 
   padding-left:14px; 
   font-family: 'Courgette', cursive;
   margin-top: 100px;  
   color: #8e909b;
 }

.btn1 {
  background-image: url('./images/metbg.jpg');
  border: 4px outset;
  /*border-radius: 10px;*/
  background-color: red;
  padding: 5px;
  color: red;
  width: 180px;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Courgette', cursive;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.45);
}

.btn1:hover {
  transform: scale(1.15);
}

.img1 {
  border-radius: 8px;
  box-Shadow: 0 4px 8px 5px rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.45);
}
.img1:hover {
  transform: scale(1.15);
}

#biodiv, #aboutdiv{
  margin-top: 60px;
  font-family: 'Courgette', cursive;
}

.contactModal {
  display: none;
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.7); 
}

.modal-content {
  font-family: 'Courgette', cursive;
  /*background-color: #fefefe;*/
  margin: 5% auto;
  padding: 20px;
  border: 6px outset;
  width: 50%;
  /*background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;*/
  background-image: url('./images/metbg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /*border-radius: 10px;*/
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.45);
  z-index: 1;
}

#menu{
  margin-top: 75px;
  margin-bottom: 75px;
  display: flex;
  justify-content: center;
}

#menuDiv {
  font-family: 'Courgette', cursive;
  background-image: url('./images/metbg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 6px outset;
  width: 50%;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.45);
}

table, tr {
  width: 75%;
  margin-right: 10%;
  margin-left: 15%;
}

.tdLeft{
  margin: 100px;
  text-align: left;
  width: 70%;
}

.tdRight {
  width: 30%;
}

#menuImg {
  width: 50%;
  box-Shadow: 0 4px 8px 5px rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.45);
}

#bioImg {
  width: 220px;
  margin: 15px;
  border-radius: 100%;
}

.close {
  position: absolute;
  top: 0px;
  right: 5px;
  color: black;
  font-size: 28px;
  font-weight: bold;
  float: right;
}

.close:hover, .close:focus {
  color: red;
  text-decoration: none;
  cursor: pointer;
}

input[type=text], input[type=email], input[type=file], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.45);
}

#subject {
  white-space: pre-line;
  max-height: 400px;
}

input[type=file] {
  max-height: 55px;
}

.bioSection {
  margin-bottom: 150px;
}

@media screen and (max-width: 1080px) {
  .modal-content {
    width: 95%;
    z-index: 1;
  }
}

@media screen and (max-width: 800px) {
  #googMap {
    	width: 100%;
	    margin-bottom: 5%;
  }

  .promo {
    width: 100%;
  }

  #menuImg {
    width: 80%;
  }

  .homeImg1, .homeImg2, .homeImg3 {
    width: 29%;
  }

  .servprod {
    width: 100%;
  }

  #bioImg {
    float: none;
    margin: 15px;
    width: 90%;
  }

  .bioSection {
    margin-bottom: 50px;
  }

  #biodiv, #aboutdiv{
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  #prodimg {
    float: none;
    text-align: center;
    width: 85%;
  }

  #proddiv {
    margin-top: 0;
    margin-bottom: 150px;
  }

  .modal-content {
    width: 95%;
    z-index: 1;
  }

  #homeHead {
    width: 96%;
    margin-right: 3%;
    margin-left: 3%;
  }

  .rc_nav a, #fullHome {
    display: none;
  }
  .rc_nav a.icon {
      float: right;
      display: block;
      width: 60px;
  }
  .aboutdiv {
    width: 50%;
  }
  .rc_nav.responsive .icon {
    position: fixed;
    right: 0;
    top: 0;
  }

  #rc_logo {
    display: block;
  }

  .rc_nav {
    height: auto;
  }

  .rc_nav.responsive a {
    float: none;
    display: block;
    text-align: center;
  }

  #menuDiv {
    width: 90%;
  }

  table, tr {
    width: 100%;
    margin-right:3%;
    margin-left: 5%;
  }

}

.prodImg {
  border-radius: 10px;
  box-Shadow: 0 4px 8px 5px rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.45);
}

a.nav-link:hover{
  cursor: pointer;
  border-radius: 5px;
  background: red;
  color: red;
}

a.nav-link {
font-size: 15px;
font-family: 'Courgette', cursive;
}

.grad1 {
  background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  justify-content: center;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 7px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 40%;
    max-width: 40%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 42%;
    max-width: 42%;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 100px;
  height: 100px;
  border: 20px solid #f6f6f6; /* Light grey */
  border-top: 20px solid #aaa9a7; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
}


